var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"card-custom",attrs:{"header-tag":"header","footer-tag":"footer"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("HEALTH_CARDS"))+" "),_c('i',{staticClass:"mr-2"}),_c('small',{},[_vm._v(_vm._s(_vm.$t("LIST")))])])])]},proxy:true},{key:"footer",fn:function(){return undefined},proxy:true}])},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPageLoading),expression:"!isPageLoading"}]},[_c('SearchTools',{attrs:{"searchQuery":_vm.searchQuery},on:{"update:searchQuery":function($event){_vm.searchQuery=$event},"update:search-query":function($event){_vm.searchQuery=$event},"do-search":_vm.doSearch,"do-clear":_vm.doClear},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-col',[_c('FormDatepickerBase',{attrs:{"type":'text',"clearable":true,"i18n":{
                label: 'HEALTH_CARD_VALID',
                placeholder: _vm.$t('HEALTH_CARD_VALID')
              },"date":_vm.filters.dateTo},on:{"update:date":function($event){return _vm.$set(_vm.filters, "dateTo", $event)}}})],1),_c('b-col',[_c('FormSelect',{attrs:{"id":"store-city-input","type":"select","name":"stores","options":_vm.stores,"item-text":"name","item-value":"id","clearable":"true","i18n":{
                label: _vm.$t('STORE'),
                placeholder: _vm.$t('STORE')
              }},model:{value:(_vm.filters.stores),callback:function ($$v) {_vm.$set(_vm.filters, "stores", $$v)},expression:"filters.stores"}})],1)]},proxy:true}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"item-class":_vm.expiringHealthCardRow,"sort-by":'date_to',"sort-desc":false,"options":_vm.options,"items-per-page":_vm.totalItems,"server-items-length":_vm.totalItems,"loading":_vm.isLoadingHealthStore,"loading-text":"Loading... Please wait","footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: _vm.rowsPerPageItems,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right'
        }},on:{"update:options":[function($event){_vm.options=$event},function($event){return _vm.getDataFromApi()}]},scopedSlots:_vm._u([{key:"item.user.first_name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.user.first_name)+" ")]}},{key:"item.user.last_name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.user.last_name)+" ")]}},{key:"item.date_to",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_to))+" ")]}},{key:"item.user.stores",fn:function({ item }){return [_vm._v(" "+_vm._s(item.user.stores.join(" | "))+" ")]}},{key:"item.date_till",fn:function({ item }){return [_vm._v(" "+_vm._s(item.date_till < 0 ? "Expired" : item.date_till == 0 ? "Today" : item.date_till + (item.date_till == 1 ? " day" : " days"))+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('ActionButton',{attrs:{"to":{
              name: 'edit-user',
              params: { id: item.user.id },
              query: { tab: 'health-cards' }
            },"permissions":['users-update'],"label":_vm.$t('EDIT'),"icon":'mdi-pencil'}})]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" Your search for \""+_vm._s(_vm.searchQuery)+"\" found no results. ")])]},proxy:true}])})],1),(_vm.isPageLoading)?_c('v-skeleton-loader',{attrs:{"loading":_vm.isPageLoading,"type":"table"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }