<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("HEALTH_CARDS") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LIST") }}</small>
          </h3>
        </div>
        <!--        <div class="card-toolbar">-->
        <!--          <router-link-->
        <!--            to="add"-->
        <!--            class="btn btn-light-success font-weight-bolder mr-2"-->
        <!--            ><i class="flaticon2-plus"></i>-->
        <!--            {{ $t("ADD_NEW") }}</router-link-->
        <!--          >-->
        <!--        </div>-->
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend>
            <b-col>
              <FormDatepickerBase
                :type="'text'"
                :clearable="true"
                :i18n="{
                  label: 'HEALTH_CARD_VALID',
                  placeholder: $t('HEALTH_CARD_VALID')
                }"
                :date.sync="filters.dateTo"
              ></FormDatepickerBase>
            </b-col>
            <b-col>
              <FormSelect
                id="store-city-input"
                type="select"
                name="stores"
                :options="stores"
                item-text="name"
                item-value="id"
                clearable="true"
                v-model="filters.stores"
                :i18n="{
                  label: $t('STORE'),
                  placeholder: $t('STORE')
                }"
              ></FormSelect>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :item-class="expiringHealthCardRow"
          :sort-by="'date_to'"
          :sort-desc="false"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingHealthStore"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="elevation-1"
        >
          <template v-slot:item.user.first_name="{ item }">
            {{ item.user.first_name }}
          </template>

          <template v-slot:item.user.last_name="{ item }">
            {{ item.user.last_name }}
          </template>

          <template v-slot:item.date_to="{ item }">
            {{ item.date_to | formatDate }}
          </template>

          <template v-slot:item.user.stores="{ item }">
            {{ item.user.stores.join(" | ") }}
          </template>

          <template v-slot:item.date_to="{ item }">
            {{ item.date_to | formatDate }}
          </template>

          <template v-slot:item.date_till="{ item }">
            {{
              item.date_till < 0
                ? "Expired"
                : item.date_till == 0
                ? "Today"
                : item.date_till + (item.date_till == 1 ? " day" : " days")
            }}
          </template>

          <template v-slot:item.actions="{ item }">
            <ActionButton
              :to="{
                name: 'edit-user',
                params: { id: item.user.id },
                query: { tab: 'health-cards' }
              }"
              :permissions="['users-update']"
              :label="$t('EDIT')"
              :icon="'mdi-pencil'"
            />
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import SearchTools from "@/core/components/table/SearchTools.vue";
import ActionButton from "@/core/components/table/ActionButton.vue";

//Health Card Module
import { FETCH_HEALTH_CARDS } from "@/modules/health-card/store/health-card.module";

//Fetch stores
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      healthCards: [],
      healthCardsParams: {
        page: 1,
        itemsPerPage: 0,
        query: ""
      },
      items: [],
      stores: [],
      totalItems: 100,
      searchQuery: "",
      filters: {
        stores: "",
        dateTo: ""
      },
      options: {
        descending: false,
        page: 1,
        itemsPerPage: 10,
        totalItems: 100
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id"
        },
        { text: "First Name", value: "user.first_name" },
        { text: "Last Name", value: "user.last_name" },
        { text: "Stores", value: "user.stores" },
        { text: "Description", value: "description" },
        { text: "Valid", value: "date_to" },
        { text: "Expire", value: "date_till" },
        {
          align: "right",
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  components: {
    SearchTools,
    FormDatepickerBase,
    FormSelect,
    ActionButton
  },
  watch: {},
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Health Cards", route: { name: "list-health-cards" } },
      { title: "Health Cards List" }
    ]);
    vm.getSelects();
  },
  computed: {
    ...mapGetters(["getHealthCards", "isLoadingHealthCard"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
        dateTo: this.filters.dateTo,
        stores: this.filters.stores
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    }
  },
  methods: {
    expiringHealthCardRow(item) {
      return item.date_till <= item.expiration_alert ? "text-danger" : "";
    },
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      vm.filters.dateTo = "";
      vm.filters.stores = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_HEALTH_CARDS, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            this.$router.push({ name: "list-health-cards" });
          }
        });
    },
    getSelects() {
      let vm = this;
      let payload = {
        stores: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.stores = data.data.stores;
        })
        .catch(response => {
          console.log(response);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getColor(active) {
      if (active == false || active == 0) return "red";
      else return "primary";
    },
    textIsActive(active) {
      if (active == false || active == 0) return "Not Active";
      else return "Active";
    },
    iconIsActive(active) {
      if (active == false || active == 0) return "mdi-close-circle-outline";
      else return "mdi-checkbox-marked-circle-outline";
    }
  }
};
</script>
